import React from 'react';
import { Link } from 'gatsby';
import Menu from '../menu';
import SkinDeepLogo from '../skin-deep-logo';
import BasketIndicator from '../basket-indicator';
import SecondaryLogo from '../secondary-logo';

import './header.scss';

const Header = ({ colorFill, showBasket, categories }
  : {colorFill: 'black' | 'white', showBasket: boolean, categories: string | string[]}) => (
    <header className="header">
      <div className="header-logo">
        <Link to="/"><SkinDeepLogo fill={colorFill} /></Link>
        <SecondaryLogo categories={categories} />
      </div>
      <div className="header-buttons">
        { showBasket ? <BasketIndicator /> : ''}
        <Menu fill={colorFill} />
      </div>
    </header>
);

export default Header;
