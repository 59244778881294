import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import './menu-list.scss';

const menuItems = [
  ['Seasons', '/seasons', 'seasons'],
  ['Learnings', '/articles/category/learnings', 'learnings'],
  ['Reflections', '/articles/category/reflections', 'reflections'],
  ['Actions', '/articles/category/actions', 'background'],
  ['Skin Deep Meets', '/articles/category/skindeepmeets', 'meets'],
  ['Videos', '/articles/type/video/', 'video'],
  ['Events', '/events', 'events'],
  ['Back Issues / Shop', '/products', 'shop'],
  ['About', '/about-us', 'about'],
  ['Membership', '/become-a-member', 'support'],
  ['Search', '/search', 'search'],
];

const getImageUrl = (image: string) => `/images/menu-${image}.jpg`;

const MenuList = () => {
  const [imageUrl, setImageUrl] = useState('/images/menu-background.jpg');

  useEffect(() => {
    menuItems.forEach(([,, image]) => {
      const newImage = new Image();
      newImage.src = getImageUrl(image);
    });
  }, []);

  const showImage = (image: string) => {
    setImageUrl(getImageUrl(image));
  };

  return (
    <>
      <div className="menu-background-image">
        <img src={imageUrl} alt="" loading="lazy" />
      </div>
      <ul className="menu-list">
        {menuItems.map(([label, target, image]) => (
          <li
            key={target}
            onMouseOver={() => showImage(image)}
            onFocus={() => showImage(image)}
          >
            <Link to={target}>{label}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MenuList;
