import React from 'react';
import Footer from '../footer';
import Header from '../header';
import './layout.scss';

const Layout = ({
  children, style, scheme, showBasket, categories,
}:
  {
    children: React.ReactNode,
    style?: React.CSSProperties,
    scheme?: 'light' | 'dark',
    showBasket?: boolean,
    categories?: string | string[]
  }) => (
    <>
      <div
        className={`main-wrapper${scheme === 'dark' ? ' main-wrapper--dark' : ''}`}
        style={style}
      >
        <Header
          colorFill={scheme === 'dark' ? 'white' : 'black'}
          showBasket={showBasket}
          categories={categories}
        />
        <main>
          {children}
        </main>
      </div>
      <Footer />
    </>
);

Layout.defaultProps = {
  style: null,
  scheme: 'light',
  showBasket: false,
  categories: '',
};

export default Layout;
