import React from 'react';
import { Link } from 'gatsby';
import SkinDeepLogo from '../skin-deep-logo';
import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer-inner">
      <div className="footer-menu">
        <ul className="footer-column">
          <li>
            <Link className="link-text" to="/submissions">Pitch/submit</Link>
          </li>
          <li>
            <Link className="link-text" to="/work-with-us">Work with us</Link>
          </li>
          <li>
            <Link className="link-text" to="/privacypolicy">Privacy policy</Link>
          </li>
        </ul>
        <ul className="footer-column">
          <li>
            <Link className="link-text" to="/subscribe-newsletter">Subscribe to our newsletter</Link>
          </li>
          <li>
            <Link className="link-text" to="/donate">Support us</Link>
          </li>
        </ul>
      </div>
      <div className="footer-bottom">
        <SkinDeepLogo fill="white" />
      </div>
    </div>
  </footer>
);

export default Footer;
