import React, { useState } from 'react';
import SkinDeepLogo from '../skin-deep-logo';

import './menu.scss';
import MenuList from '../menu-list';

const Menu = ({ fill } : {fill: string}) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  return (
    <div className="menu">
      <button
        type="button"
        className="menu-button menu-button--close"
        onClick={() => setMenuVisible(true)}
      >
        <svg
          width="44"
          height="33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ color: fill }}
        >
          <path
            d="M1 26.2H.5v6h43v-6H1zM1 13.5H.5v6h43v-6H1zM1 .5H.5v6h43v-6H1z"
            stroke="currentColor"
          />
        </svg>
      </button>
      <div className={`menu-container${isMenuVisible ? ' is-visible' : ''}`}>
        <div className="menu-container-inner">
          <div className="menu-close-button-container">
            <SkinDeepLogo fill="white" />
            <button type="button" className="menu-button" onClick={() => setMenuVisible(false)}>
              <svg width="41" height="41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.6 41l16-16 15.8 16 4.6-4.7-15.8-15.8L41 4.6 36.4 0 20.5 15.8 4.6 0 0 4.6l16 15.9L0 36.3 4.6 41z"
                  fill="#fff"
                />
              </svg>
            </button>
          </div>
          <MenuList />
        </div>
      </div>
    </div>
  );
};

export default Menu;
