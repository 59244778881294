import React from 'react';

import './secondary-logo.scss';

const LOGOS = [
  { name: 'Search', file: 'search' },
  { name: 'Season', file: 'season' },
  { name: 'Shop', file: 'shop' },
  { name: 'Reflections', file: 'reflections' },
  { name: 'Learnings', file: 'learnings' },
  { name: 'Actions', file: 'actions' },
  { name: 'Skin Deep Meets', file: 'meets' },
  { name: 'Videos', file: 'videos' },
  { name: 'Events', file: 'events' },
];

const SecondaryLogo = ({ categories } : {categories: string | string[]}) => {
  const logoCategories = [].concat(categories);
  let imageFile;

  for (let i = 0; i < LOGOS.length; i += 1) {
    if (logoCategories.includes(LOGOS[i].name)) {
      imageFile = LOGOS[i].file;
      break;
    }
  }

  return (
    <>
      <div data-test={categories} />
      {imageFile ? (
        <div className="secondary-logo">
          <img src={`/images/header/${imageFile}.svg`} alt="" />
        </div>
      ) : ''}
    </>
  );
};

export default SecondaryLogo;
