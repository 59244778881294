import React from 'react';
import './basket-indicator.scss';

const BasketIndicator = () => (
  <div className="basket-indicator snipcart-checkout">
    <svg width="31" height="46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.4 9.7C5.8 4.3 10.2 0 15.5 0s9.7 4.3 10 9.7h4.3c.7 0 1.2.5 1.2 1.2v33.9c0 .7-.5 1.2-1.2 1.2H1.2C.5 46 0 45.5 0 44.8v-34c0-.6.5-1.1 1.2-1.1h4.2zm-3 2.4v31.5h26.2V12H2.4zm20.7-2.4c-.3-4-3.6-7.3-7.6-7.3-4 0-7.3 3.2-7.6 7.3H23z" fill="#000" />
    </svg>
  </div>
);

export default BasketIndicator;
