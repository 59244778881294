import React from 'react';

import './skin-deep-logo.scss';

const SkinDeepLogo = ({ fill } : {fill: 'black' | 'white'}) => (
  <svg
    className="skin-deep-logo"
    width="237"
    height="60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6 1C5.7 1 1.2 5.6 1.2 13.2V16C1.2 22 3 26.5 7.8 30.8l6.6 6c2.8
      2.5 3 4 3 7.2v1.5c0 2.3-.5 3.4-2 3.4-1.7 0-2.2-1-2.2-3v-8H.7v9.2c0 8.4 4.5
      12.7 13.3 12.7h2.8c9.8 0 14.2-6 14.2-15V42c0-6.3-1.8-10.1-7.6-15.4L17
      20.7c-2.3-2-2.8-3.4-2.8-5.8v-1.5c0-1.6.5-2.5 2-2.5s1.8.9 1.8
      2.4v6.2h12.3v-6.9C30.2 5.5 26.8 1 17.5 1h-2.9zM45.7 36L51.4
      59h13.4l-8.3-31.7 7.8-25.6H51.7l-6 20.3V1.7H33V59h12.7V36.1zM78.5
      1.7H65.8V59h12.7V1.7zm2.7 0V59h11V29.1L99.9 59H111V1.7H100v27.7L93.6
      1.7H81.2zm41 57.3h18.3c7.4 0 11.4-4.2
      11.4-13.2V14.3c0-9.1-4.4-12.6-11.5-12.6h-18.2V59zm12.6-46.7h1.9c1.8
      0 2.2 1 2.2 3.3v29c0 2.3-.5 3.4-2.4
      3.4h-1.7V12.3zm19.8-10.6V59h24.2V47h-11.7V35.4h9V23.3h-9v-9.7h11.3V1.7h-23.8zm26.3
      0V59H205V47h-11.7V35.4h9V23.3h-9v-9.7h11.3V1.7h-23.8zm45 35.2c6.6 0
      10.4-4.3 10.4-11.8V13.3c0-7.7-3.2-11.6-10.8-11.6h-18.3V59h12.6V36.9h6.1zm-6-25.2h1.5c1.9
      0 2.2 1.1 2.2 2.9v9c0 1.8-.4 2.8-2.2 2.8h-1.6V11.7z"
      fill={fill}
    />
  </svg>
);

export default SkinDeepLogo;
